import {
  Auth,
  browserSessionPersistence,
  getAuth,
  inMemoryPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithCustomToken,
  User,
} from "firebase/auth";
import { getDatabase } from "firebase/database";
import React, { useCallback, useEffect, useState } from "react";
import { AuthProvider, DatabaseProvider, FirebaseAppProvider, useAuth, useFirebaseApp } from "reactfire";

import { ErrorMessage } from "../components/shared/ErrorMessage";
import { LoadingPage } from "../components/shared/LoadingPage";
import { getErrorMessage } from "../utils";
import { useApplicationContext } from "./ApplicationContextProvider";

const config = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  persistence: browserSessionPersistence,
};

interface FirebaseContextProps {
  children?: React.ReactNode;
  forceInitialSignIn?: boolean;
}

async function signIn(auth: Auth, token: string) {
  await setPersistence(auth, inMemoryPersistence);
  await signInWithCustomToken(auth, token);
  try {
    await setPersistence(auth, browserSessionPersistence);
  } catch (error) {
    console.warn("Failed to use session storage for firebase auth persistence", error);
  }
}

function FirebaseServiceProvider({ children }: Readonly<FirebaseContextProps>) {
  const app = useFirebaseApp();
  const auth = getAuth(app);
  const database = getDatabase(app);
  return (
    <DatabaseProvider sdk={database}>
      <AuthProvider sdk={auth}>{children}</AuthProvider>
    </DatabaseProvider>
  );
}

function FirebaseAuthProvider({ children, forceInitialSignIn }: Readonly<FirebaseContextProps>) {
  const auth = useAuth();
  const { firebaseToken, userAccountId } = useApplicationContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const isUserAuthenticated = useCallback(
    (user: User | null) => {
      if (!user) return false;
      const [userId] = user.uid.split(":");
      return userId === userAccountId;
    },
    [userAccountId],
  );

  useEffect(() => {
    async function signInWithToken() {
      try {
        await signIn(auth, firebaseToken);
      } catch (e) {
        let errorMessage = getErrorMessage(e);
        const isThirdPartyCookiesError = errorMessage === "Firebase: Error (auth/network-request-failed).";
        if (isThirdPartyCookiesError) {
          errorMessage =
            "In order for our application to function properly, you must have third-party cookies enabled on your browser.";
        }
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    }

    onAuthStateChanged(auth, (user) => {
      if (!isUserAuthenticated(user) || forceInitialSignIn) {
        void signInWithToken();
      } else {
        setIsLoading(false);
      }
    });
  }, [auth, firebaseToken, userAccountId, isUserAuthenticated, forceInitialSignIn]);

  if (error) {
    return <ErrorMessage title="Something went wrong" text="Unexpected error happened when connecting to Firebase." />;
  } else {
    return <>{isLoading ? <LoadingPage /> : children}</>;
  }
}

export function FirebaseProvider({ children, forceInitialSignIn = false }: Readonly<FirebaseContextProps>) {
  return (
    <FirebaseAppProvider firebaseConfig={config}>
      <FirebaseServiceProvider>
        <FirebaseAuthProvider forceInitialSignIn={forceInitialSignIn}>{children}</FirebaseAuthProvider>
      </FirebaseServiceProvider>
    </FirebaseAppProvider>
  );
}
