import React from "react";

import Lozenge from "@atlaskit/lozenge";

import { ChangelogEntry } from "./ChangelogEntry";

export function AppfireStatement() {
  return (
    <ChangelogEntry title="Appfire Position Statement on the war in Ukraine and Russia" date={new Date("2022-03-02")}>
      <Lozenge appearance="removed">Statement</Lozenge>
      <p>
        It is Appfire&apos;s position that we proudly stand with our Ukrainian teammates, their families, and all of
        Ukraine against Vladimir Putin and the Russian military and government. We condemn the military and political
        actions of Russia against Ukraine, and we will not support a Russian military or political presence in Ukraine.
      </p>
      <p>
        Appfire has served the greater Atlassian community for sixteen years and, like many of you, we have customers
        and employees all over the world. Right now, we have team members leaving their homes in Ukraine, some of whom
        are staying with Appfire colleagues who have opened their hearts and homes in Bulgaria and Poland due to the
        unjust and illegal acts of war taking place in Ukraine.
      </p>
      <p>
        To that end, consistent with our values and with US sanctions, we will no longer sell our technology or provide
        support services to Russia, Belarus, or any other entity that supports the invasion of Ukraine.
      </p>
      <p>
        The ordinary citizens of Russia are not to blame for this crisis, and we will continue to support our Appfire
        employees and family members in Russia.
      </p>
      <p>
        Appfire is actively focused on helping our people in the region. We are providing alternate communication
        channels, offering financial assistance to team members and their families, adjusting compensation to account
        for local cost of living for relocated team members, providing dedicated channels for resource sharing and
        support, and making sure everyone can take as much paid time off as they need during this time. We are also
        working with our partners at Pledge 1% and have team members mobilizing independently to raise funds for
        Ukraine.
      </p>
      <p>
        We are not alone in our grief, expression of outrage, and desire to take a stand. We encourage Atlassian and all
        our friends in the ecosystem and technology community to join us by taking similar actions in support of the
        Ukrainian people.
      </p>
      <p>
        I stand in solidarity with the people of Ukraine and hope for peace and justice in Ukraine as soon as possible.
      </p>
      <p>– Randall Ward, CEO and Co-Founder, Appfire</p>
      <p>
        Original statement can be found{" "}
        <a
          href="https://www.linkedin.com/pulse/appfire-position-statement-ukraine-crisis-russia-randall-ward"
          title="Appfire Position Statement on the Ukraine Crisis and Russia"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
      </p>
    </ChangelogEntry>
  );
}
