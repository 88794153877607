import React, { useMemo } from "react";
import styled from "styled-components";

import Lozenge, { ThemeAppearance } from "@atlaskit/lozenge";

interface VotingCounterProps {
  votedParticipantsCount: number;
  votingParticipantsCount: number;
}

const VotingCounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export function VotingCounter({ votedParticipantsCount, votingParticipantsCount }: Readonly<VotingCounterProps>) {
  const appearance = useMemo<ThemeAppearance>(() => {
    if (!votedParticipantsCount) {
      return "default";
    }
    if (votedParticipantsCount < votingParticipantsCount) {
      return "inprogress";
    }
    return "success";
  }, [votedParticipantsCount, votingParticipantsCount]);

  return (
    <VotingCounterWrapper>
      <Lozenge isBold appearance={appearance}>
        {votedParticipantsCount}/{votingParticipantsCount} VOTED
      </Lozenge>
    </VotingCounterWrapper>
  );
}
