import React from "react";
import styled from "styled-components";

import { EstimationHeaderProps, ItemState } from "@appfire/poker-core";
import { themeGet } from "@fuegokit/react";

import { JiraIssue } from "../../../types";
import { TimerControls } from "../GameControls/components/TimerControls";
import { useGameData } from "../GameProvider";
import { AdminActions } from "./AdminActions";
import { VotingCounter } from "./VotingCounter";

const EstimationHeaderContainer = styled.div`
  border-radius: 3px;
  background: ${themeGet("colors.background.accent.purple.subtlest")};
  width: 100%;
  margin-top: 16px;
  height: 40px;
  padding: 4px;
  display: flex;

  > div {
    flex: 1;
  }
`;

const StyledTimerControls = styled(TimerControls)`
  justify-content: flex-end;
`;

export function EstimationHeader(props: Readonly<EstimationHeaderProps<JiraIssue>>) {
  const { isGameAdmin } = useGameData();
  const { activeItem, activeItemState, votedParticipantsCount, votingParticipantsCount, revealVotes } = props;

  if (!activeItem) return null;

  return (
    <EstimationHeaderContainer>
      <div>{isGameAdmin && <AdminActions {...props} />}</div>
      <VotingCounter
        votedParticipantsCount={votedParticipantsCount}
        votingParticipantsCount={votingParticipantsCount}
      />
      <div>
        {activeItemState === ItemState.PERSONAL_ESTIMATION ? (
          <StyledTimerControls isGameAdmin={isGameAdmin} onTimerEnd={revealVotes} />
        ) : null}
      </div>
    </EstimationHeaderContainer>
  );
}
