import React, { useMemo } from "react";
import styled from "styled-components";

import Lozenge, { ThemeAppearance } from "@atlaskit/lozenge";

import { useEstimationField } from "../../../hooks/useEstimationField";
import { getEstimationValue } from "../../../utils/estimation-values";
import { useGameIssues } from "../GameIssuesProvider";
import { useGameData } from "../GameProvider";

const EstimationInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
`;

export function EstimatedIssuesHeader() {
  const { game } = useGameData();
  const estimationField = useEstimationField(game.configuration.estimationFieldId);

  const { issues, estimatedIssues } = useGameIssues();
  const issuesValues = useMemo(() => Object.values(issues), [issues]);
  const estimatedIssuesValues = useMemo(() => Object.values(estimatedIssues), [estimatedIssues]);

  const lozengeAppearance = useMemo<ThemeAppearance | undefined>(() => {
    if (!estimatedIssuesValues?.length) return;

    return estimatedIssuesValues.length < issuesValues.length ? "inprogress" : "success";
  }, [estimatedIssuesValues.length, issuesValues.length]);

  const estimationSum = useMemo(
    () =>
      issuesValues.reduce<number | null>((sum, issue) => {
        const issueEstimationValue = issue.fields[game.configuration.estimationFieldId];
        if (sum === null || !issueEstimationValue) return sum;
        const numberValue = Number(issueEstimationValue);
        if (isNaN(numberValue)) return null;
        return sum + numberValue;
      }, 0),
    [game.configuration.estimationFieldId, issuesValues],
  );

  const estimationSumValue = useMemo(
    () => getEstimationValue(estimationSum, estimationField),
    [estimationField, estimationSum],
  );

  return (
    <EstimationInfoWrapper>
      <Lozenge appearance={lozengeAppearance} isBold>
        ESTIMATED ISSUES: {estimatedIssuesValues.length}/{issuesValues.length}
      </Lozenge>
      <Lozenge appearance="success" maxWidth={350}>
        {estimationField?.name &&
          estimationSumValue !== null &&
          `${estimationField.name.toUpperCase()} ESTIMATED: ${estimationSumValue}`}
      </Lozenge>
    </EstimationInfoWrapper>
  );
}
