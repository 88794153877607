import React, { useCallback } from "react";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { getSupportURL } from "../../utils/feedback";
import { ChangelogEntry } from "./ChangelogEntry";

export function August2024() {
  const { hostBaseUrl, addonKey } = useApplicationContext();

  const openSupportUrl = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      void getSupportURL(addonKey, hostBaseUrl).then((supportURL) =>
        window.open(supportURL, "_blank", "noreferrer noopener"),
      );
    },
    [addonKey, hostBaseUrl],
  );

  return (
    <ChangelogEntry title="August 2024 update" date={new Date("2024-08-26")}>
      <p>Planning Poker has received multiple enhancements to offer a more efficient experience. Here’s what’s new:</p>
      <ul>
        <li>
          <strong>Save and finish games with unestimated items.</strong> No more worries about incomplete planning
          sessions.
        </li>
        <li>
          <strong>Directly access Jira for estimated issues.</strong> Seamlessly transition from planning to execution.
        </li>
        <li>
          <strong>Clone games with backlog duplication.</strong> Quickly set up similar planning sessions without
          starting from scratch.
        </li>
        <li>
          <strong>Search for specific items more easily.</strong> Find the exact issues you need with improved search
          functionality.
        </li>
      </ul>
      <p>
        These updates aim to simplify your planning process and boost team productivity. Try it out today and{" "}
        <a href="#" onClick={openSupportUrl} target="_blank" rel="noopener noreferrer">
          let us know what you think
        </a>
        ! For more details, please refer to our{" "}
        <a
          href="https://appfire.atlassian.net/wiki/spaces/PP/pages/1234108523/Release+notes+August+2024"
          target="_blank"
          rel="noopener noreferrer"
        >
          release notes
        </a>
        .
      </p>
    </ChangelogEntry>
  );
}
