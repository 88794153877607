import React, { ReactElement } from "react";

import { BaseStyles, ThemeProvider as FuegokitThemeProvider } from "@fuegokit/react";
import { ColorModeWithAuto } from "@fuegokit/react/lib/components/ThemeProvider/ThemeProvider";

import "@fuegokit/tokens/dist/css/atlassian/colors/light.css";
import "@fuegokit/tokens/dist/css/atlassian/colors/dark.css";

import { useThemeObserver } from "../hooks/useThemeObserver";

const colorModes: Record<string, ColorModeWithAuto> = {
  light: "day",
  dark: "night",
  auto: "auto",
};

export function ThemeProvider({ children }: Readonly<{ children: ReactElement }>) {
  const colorMode = useThemeObserver();

  return (
    <FuegokitThemeProvider colorMode={colorModes[colorMode ?? "light"] as ColorModeWithAuto}>
      <BaseStyles />
      {children}
    </FuegokitThemeProvider>
  );
}
