import { JiraStatus } from "../types";

export const FIELD = {
  ISSUE_TYPE: "issuetype",
  ISSUE_KEY: "issuekey",
  SUMMARY: "summary",
  EPIC: "epic",
  STATUS: "status",
  ESTIMATE: "estimate",
  PRIORITY: "priority",
  PARENT: "parent",
  ASSIGNEE: "assignee",
  DESCRIPTION: "description",
  ATTACHMENT: "attachment",
  COMMENT: "comment",
  GAME_ESTIMATION: "game_estimation",
};

export enum CUSTOM_FIELD {
  PARENT = "com.atlassian.jpo:jpo-custom-field-parent",
  URL = "com.atlassian.jira.plugin.system.customfieldtypes:url",
  PARAGRAPH = "com.atlassian.jira.plugin.system.customfieldtypes:textarea",
}

export const DEFAULT_COLUMNS = [
  FIELD.ISSUE_TYPE,
  FIELD.ISSUE_KEY,
  FIELD.SUMMARY,
  FIELD.EPIC,
  FIELD.STATUS,
  FIELD.ESTIMATE,
  FIELD.PRIORITY,
  FIELD.ASSIGNEE,
];

export const CUSTOM_HEADERS_TITLES: Record<string, string> = {
  [FIELD.ISSUE_TYPE]: "T",
  [FIELD.PRIORITY]: "P",
  [FIELD.GAME_ESTIMATION]: "Est.",
};

export function buildJQLClause(name: string | undefined, statuses: JiraStatus[]) {
  const projectClause = name ? `project = "${name}"` : "";
  const toDoStatuses = statuses.filter((s) => s.statusCategory.key === "new");
  if (toDoStatuses.length > 0) {
    const statusClause = `status IN (${toDoStatuses.map((s) => `"${s.untranslatedName}"`).join(", ")})`;
    return projectClause ? projectClause.concat(" AND ").concat(statusClause) : statusClause;
  }
  return projectClause;
}
