import React, { useCallback } from "react";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { getSupportURL } from "../../utils/feedback";
import { ChangelogEntry } from "./ChangelogEntry";

export function September2024() {
  const { hostBaseUrl, addonKey } = useApplicationContext();

  const openSupportUrl = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      void getSupportURL(addonKey, hostBaseUrl).then((supportURL) =>
        window.open(supportURL, "_blank", "noreferrer noopener"),
      );
    },
    [addonKey, hostBaseUrl],
  );

  return (
    <ChangelogEntry title="September 2024 update" date={new Date("2024-09-10")}>
      <p>
        We’re pleased to announce a valuable enhancement to your Planning Poker experience. You can now expand
        attachments within the description field and comments to full-screen size with a simple click. This provides a
        more detailed view of important visual content, enhancing your collaboration and decision-making process.
      </p>
      <p>
        Try it out today and{" "}
        <a href="#" onClick={openSupportUrl} target="_blank" rel="noopener noreferrer">
          let us know
        </a>{" "}
        what you think! For more details, you can refer to the{" "}
        <a
          href="https://appfire.atlassian.net/wiki/spaces/PP/pages/1269596801/Release+notes+September+2024"
          target="_blank"
          rel="noopener noreferrer"
        >
          release notes
        </a>
        .
      </p>
    </ChangelogEntry>
  );
}
