import LogRocket from "logrocket";
import { useCallback } from "react";

import { useApplicationContext } from "../../providers/ApplicationContextProvider";
import { getIdentity } from "../utils/fingerprint-service";

const LOGROCKET_PROJECT_ID = process.env.LOGROCKET_PROJECT_ID as string;

export function useLogRocket() {
  const { userAccountId, clientKey, license, hostBaseUrl } = useApplicationContext();

  const initLogRocket = useCallback(async () => {
    if (/HeadlessChrome/.test(window.navigator.userAgent)) {
      // Do not initialize LogRocket for automated tests
      return;
    }
    LogRocket.init(LOGROCKET_PROJECT_ID, {
      dom: {
        inputSanitizer: true,
      },
    });

    const fingerprintIdentity = await getIdentity(userAccountId, clientKey, license?.entitlementNumber);

    LogRocket.identify(userAccountId, {
      license_type: license?.evaluation ? "EVALUATION" : license?.type ?? "",
      license_id: license?.entitlementNumber ?? "",
      url: hostBaseUrl,
      device_id: fingerprintIdentity ?? "",
    });
  }, [clientKey, hostBaseUrl, license?.entitlementNumber, license?.evaluation, license?.type, userAccountId]);

  return { initLogRocket };
}
